import React from "react";
import { Tabs, Tab } from "@mui/material";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BeneficiaryTabs: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = location.pathname.split("/").pop() || "unassigned";
  const [searchParams] = useSearchParams();

  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    searchParams.delete("page");
    navigate(
      `/app/project-manager/beneficiaries/${newValue}?${searchParams.toString()}`
    );
  };

  return (
    <Tabs
      value={currentTab}
      onChange={handleStatusChange}
      TabIndicatorProps={{
        sx: {
          backgroundColor: "#328AF1",
        },
      }}
      sx={{
        "& .MuiTab-root.Mui-selected": {
          color: "#328AF1",
        },
      }}
    >
      <Tab
        sx={{ fontWeight: "bold" }}
        label={t("Not Assigned")}
        value="unassigned"
      />
      <Tab
        sx={{ fontWeight: "bold" }}
        label={t("Customer Service")}
        value="customer-service"
      />
      <Tab
        sx={{ fontWeight: "bold" }}
        label={t("Negotiator")}
        value="negotiator"
      />
    </Tabs>
  );
};
